import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Card from "react-bootstrap/Card"
import * as styles from "./InvoBlogs.module.scss"

const InvoBlogs = ({ post }) => {
  const image = getImage(post?.featuredImage?.localFile)
  return (
    <Card className={styles.BlogCard}>
      <Link to={`/blog/${post?.slug}/`} style={{ minHeight: "238px" }}>
        {image ? (
          <GatsbyImage
            placeholder="blurred"
            decoding="async"
            loading="lazy"
            image={image}
            alt={post?.title}
            className={styles.InvoBlogsImg}
            style={{ height: 238, maxWidth: "100%", objectPosition: "left" }}
          />
        ) : (
          <img
            src={
              post?.isWP
                ? post?.featuredImage?.sourceUrl
                : post?.featuredImage?.localFile?.publicURL
            }
            alt={post?.title}
            className={styles.InvoBlogsImg}
            decoding="async"
            loading="lazy"
            style={{ height: 238, maxWidth: "100%", objectPosition: "left" }}
          />
        )}
      </Link>
      <Card.Body>
        <div className={styles.BtnAuther}>
          {" "}
          <Link
            to={`/blog/category/${post?.categories[0]?.slug}/`}
            className={styles.CardBtn}
          >
            {post?.categories[0]?.name}
          </Link>
          <div className={styles.authurName}>
            <Link to={`/author/${post?.writer?.slug}/`}>
              <div className="align-items-center d-flex">
                <small className={styles.avatar}>{post?.writer?.name}</small>

                <small className={`${styles.readTime} `}>
                  {" "}
                  {post?.isWP
                    ? `${post?.readingTime || 0} min read`
                    : post?.readingTime}
                </small>
              </div>
            </Link>
          </div>
        </div>
        <Link to={`/blog/${post?.slug}/`}>
          <Card.Text className={styles.CardsTitle}>{post?.title}</Card.Text>
        </Link>

        <p className={styles.CardPara}>{post?.seo?.metaDesc}</p>
      </Card.Body>
    </Card>
  )
}

export default InvoBlogs
